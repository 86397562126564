var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row q-pb-md" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailInfo",
                attrs: { title: "천연가스 (Natural gas, dried)" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "경고표지", icon: "warning_amber" },
                          on: { btnClicked: _vm.warning },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-label-text", {
                        attrs: { title: "CAS No.", value: "68410-63-9" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-label-text", {
                        attrs: { title: "UN No.", value: "1972" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-label-text", {
                        attrs: { title: "KE No.", value: "KE-25736" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-3" },
                    [
                      _c("c-label-text", {
                        attrs: { title: "EU No.", value: "270-085-9" },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm q-mb-md",
                attrs: { title: "1. 일반정보", collapsed: true },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          label: "CAS No.",
                          name: "col1",
                        },
                        model: {
                          value: _vm.data.col1,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col1", $$v)
                          },
                          expression: "data.col1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          label: "KE No.",
                          name: "col2",
                        },
                        model: {
                          value: _vm.data.col2,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col2", $$v)
                          },
                          expression: "data.col2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          label: "물질성상",
                          name: "col3",
                        },
                        model: {
                          value: _vm.data.col3,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col3", $$v)
                          },
                          expression: "data.col3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          label: "분자량",
                          name: "col4",
                        },
                        model: {
                          value: _vm.data.col4,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col4", $$v)
                          },
                          expression: "data.col4",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          label: "끓는점",
                          name: "col5",
                        },
                        model: {
                          value: _vm.data.col5,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col5", $$v)
                          },
                          expression: "data.col5",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          label: "녹는점",
                          name: "col6",
                        },
                        model: {
                          value: _vm.data.col6,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col6", $$v)
                          },
                          expression: "data.col6",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          label: "인화점",
                          name: "col7",
                        },
                        model: {
                          value: _vm.data.col7,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col7", $$v)
                          },
                          expression: "data.col7",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          disabled: true,
                          editable: _vm.editable,
                          label: "주요농도",
                          name: "col8",
                        },
                        model: {
                          value: _vm.data.col8,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col8", $$v)
                          },
                          expression: "data.col8",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c("c-table", {
              ref: "table",
              attrs: {
                title: "2. 물질정보",
                tableId: "table1",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                columnSetting: false,
                filtering: false,
                usePaging: false,
                hideBottom: true,
                gridHeight: "250px",
              },
            }),
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm msdsCard q-mb-md",
                attrs: { title: "3. 그림문자", collapsed: true },
              },
              [
                _c(
                  "template",
                  { slot: "card-detail" },
                  [
                    _c(
                      "q-intersection",
                      {
                        staticClass: "example-item",
                        staticStyle: { display: "inline" },
                      },
                      [
                        _c("img", {
                          style: { width: "100px" },
                          attrs: { src: require("@/assets/images/GHS02.gif") },
                        }),
                      ]
                    ),
                    _c(
                      "q-intersection",
                      {
                        staticClass: "example-item",
                        staticStyle: { display: "inline" },
                      },
                      [
                        _c("img", {
                          style: { width: "100px" },
                          attrs: { src: require("@/assets/images/GHS07.gif") },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm msdsCard q-mb-md",
                attrs: { title: "4. 유해위험 문구", collapsed: true },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: { editable: _vm.editable, name: "col9" },
                        model: {
                          value: _vm.data.col9,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col9", $$v)
                          },
                          expression: "data.col9",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "5. 응급조치요령", collapsed: true },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "눈에 들어갔을 때",
                          name: "col10",
                        },
                        model: {
                          value: _vm.data.col10,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col10", $$v)
                          },
                          expression: "data.col10",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "피부에 접촉했을 때",
                          name: "col11",
                        },
                        model: {
                          value: _vm.data.col11,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col11", $$v)
                          },
                          expression: "data.col11",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "흡입했을 때",
                          name: "col12",
                        },
                        model: {
                          value: _vm.data.col12,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col12", $$v)
                          },
                          expression: "data.col12",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "먹었을 때",
                          name: "col13",
                        },
                        model: {
                          value: _vm.data.col13,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col13", $$v)
                          },
                          expression: "data.col13",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm msdsCard q-mb-md",
                attrs: { title: "6. 저장방법", collapsed: true },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: { editable: _vm.editable, name: "col14" },
                        model: {
                          value: _vm.data.col14,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col14", $$v)
                          },
                          expression: "data.col14",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm q-mb-md",
                attrs: { title: "7. 피해야 할 조건 및 물질", collapsed: true },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "피해야 할 조건",
                          name: "col15",
                        },
                        model: {
                          value: _vm.data.col15,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col15", $$v)
                          },
                          expression: "data.col15",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "피해야 할 물질",
                          name: "col16",
                        },
                        model: {
                          value: _vm.data.col16,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col16", $$v)
                          },
                          expression: "data.col16",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm q-mb-md",
                attrs: {
                  title: "8. 누출 및 폭발·화재 사고시 대처방법",
                  collapsed: true,
                },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "누출",
                          name: "col17",
                        },
                        model: {
                          value: _vm.data.col17,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col17", $$v)
                          },
                          expression: "data.col17",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm q-mb-md",
                attrs: { title: "9. 법적규제현황", collapsed: true },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "노출기준",
                          name: "col18",
                        },
                        model: {
                          value: _vm.data.col18,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col18", $$v)
                          },
                          expression: "data.col18",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "특수건강진단주기",
                          name: "col19",
                        },
                        model: {
                          value: _vm.data.col19,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col19", $$v)
                          },
                          expression: "data.col19",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "작업환경측정주기",
                          name: "col20",
                        },
                        model: {
                          value: _vm.data.col20,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col20", $$v)
                          },
                          expression: "data.col20",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "산업안전보건법",
                          name: "col21",
                        },
                        model: {
                          value: _vm.data.col21,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col21", $$v)
                          },
                          expression: "data.col21",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "화학물질관리법에 의한 규제",
                          name: "col22",
                        },
                        model: {
                          value: _vm.data.col22,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col22", $$v)
                          },
                          expression: "data.col22",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-textarea", {
                        attrs: {
                          editable: _vm.editable,
                          label: "위험물안전관리법에 의한 규제",
                          name: "col23",
                        },
                        model: {
                          value: _vm.data.col23,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col23", $$v)
                          },
                          expression: "data.col23",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm msdsCard",
                attrs: { title: "9. 법적규제현황", collapsed: true },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-checkbox", {
                        attrs: {
                          editable: _vm.editable,
                          comboItems: _vm.comboItems,
                          name: "col24",
                        },
                        model: {
                          value: _vm.data.col24,
                          callback: function ($$v) {
                            _vm.$set(_vm.data, "col24", $$v)
                          },
                          expression: "data.col24",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }